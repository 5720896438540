<ng-container *ngLet="reportLanguage$ | async | reportLanguageCode as languageCode">
	<div class="d-flex justify-content-between m-2">
		<evainsights-typeahead-filter-area
			[id]="'sentiments'"
			(filterSelectionsChange)="onFilterSelection($event)"
			[filterAreaControls]="filterAreaControls"
			[filterSelections]="filterSelections$ | async"
			[showTitle]="false"
		></evainsights-typeahead-filter-area>
		<evasys-searchbox
			[ngModel]="searchValue$.value"
			[id]="'responses'"
			[language]="languageCode"
			(ngModelChange)="onSearch($event)"
		></evasys-searchbox>
	</div>
	<ng-container *ngIf="results$ | async; let filteredTopicResponses">
		<div #scrollContainer class="c-overflow-y-auto">
			<evasys-table evasysTableAreaNestedElement [id]="'responses'" [fixedLayout]="false">
				<thead class="sticky-top c-z-index-1-important rounded bg-white">
					<tr>
						<th
							evasysTableHead
							scope="col"
							data-cy="evasysTableHead-responsesTable-title"
							[position]="'start'"
							[title]="languageCode | inlineTransloco : 'reportItem.response' | async"
						>
							{{ languageCode | inlineTransloco : 'reportItem.response' | async }}
						</th>
					</tr>
				</thead>
				<tbody data-cy="tbody-responses">
					<ng-container *ngFor="let topicResponse of filteredTopicResponses.content">
						<tr class="border-top">
							<td evasys-table-column [horizontalPosition]="'start'" evasysShowMoreContainer>
								<evasys-show-more [id]="'response-' + topicResponse.response.key">
									<ngb-highlight
										[result]="topicResponse.response.value"
										[term]="searchValue$.value"
										[title]="topicResponse.response.value"
									></ngb-highlight>
								</evasys-show-more>
							</td>
						</tr>
					</ng-container>
					<evainsights-empty-content
						*ngIf="filteredTopicResponses.totalElements === 0"
						[attr.data-cy]="'emptyContent-response'"
						[message]="languageCode | inlineTransloco : 'responses.noneFound' | async"
					></evainsights-empty-content>
				</tbody>
			</evasys-table>
		</div>
		<evainsights-page-control
			*ngIf="filteredTopicResponses.totalElements > 0"
			[totalElements]="filteredTopicResponses.totalElements"
			[pageSize]="pageSize"
			[page]="pageNumber$.value"
			(pageChange)="onPageChange($event)"
			(pageSizeChange)="onPageSizeChange($event)"
		></evainsights-page-control>
	</ng-container>
</ng-container>
