<evasys-modal
	[closeable]="true"
	[id]="'reportPublish'"
	[(opened)]="isOpen"
	[size]="'lg'"
	[scrollable]="false"
	(openedChange)="openedChangeEmit($event)"
>
	<evasys-header
		header
		[id]="'reportPublishModal'"
		[headerLevel]="2"
		[text]="'report.publish.title' | transloco"
	></evasys-header>

	<form [formGroup]="form" class="d-flex flex-column gap-3 mb-4">
		<evasys-chip-typeahead
			[id]="'units'"
			[items]="searchUnits"
			[formatter]="formatterUnits"
			formControlName="units"
			[searchFailedText]="'error.text' | transloco"
			[emptyResultsText]="'unit.noneFound' | transloco"
			[itemIdentifier]="'key'"
			[errors]="[
				{
					id: 'noUnits',
					trigger: submitted && form.controls.units.errors?.required,
					text: 'unit.required' | transloco
				}
			]"
			>{{ 'organization.unit' | transloco }}</evasys-chip-typeahead
		>
		<evasys-checkbox [id]="'publishedLater'" (checkedChange)="setPublishLater($event)">{{
			'report.publish.later' | transloco
		}}</evasys-checkbox>
		<ng-container *ngIf="form.controls.dateTime">
			<h3>{{ 'domain.publishTime' | transloco }}:</h3>
			<evasys-calendar
				[id]="'date'"
				[dataCy]="'date'"
				[dateFormat]="(uiConfig | async).dateFormat"
				[timeFormat]="(uiConfig | async).timeFormat"
				[translation]="calendarPublishTranslation"
				formControlName="dateTime"
				[required]="false"
				data-cy="publishModal-dateTimePicker"
			></evasys-calendar>
			<evasys-error
				[errors]="[
					{
						id: 'expiredTime',
						trigger: submitted && form.controls.dateTime.errors?.expired,
						text: 'error.publicationTimeInPast' | transloco
					},
					{
						id: 'noDateTime',
						trigger: submitted && form.controls.dateTime.errors?.required,
						text: 'domain.publishTime.required' | transloco
					}
				]"
			></evasys-error>
		</ng-container>
	</form>

	<ngb-alert [dismissible]="false" type="secondary">{{ 'report.publish.aiActDisclaimer' | transloco }}</ngb-alert>
	<ngb-alert [dismissible]="false" type="secondary">{{ 'report.edit.notRetrospectively' | transloco }}</ngb-alert>

	<div footer class="d-flex justify-content-end w-100 gap-3">
		<evasys-button
			[id]="'publish'"
			[type]="'submit'"
			[design]="buttonDesign.PRIMARY"
			(click)="publishReport()"
			[title]="'report.publish.hover' | transloco"
			>{{ 'report.publish.title' | transloco }}
		</evasys-button>
	</div>
</evasys-modal>
