import { Page } from '../models/pagination/page.model';
import { PageQuery } from '../../../apps/evainsights-e2e/src/utils/pagination';

export const buildPage = <T>(collection: T[], { number, size }: PageQuery): Page<T> => {
	const content = collection.slice(size * number, size * (number + 1));
	const totalPages = getTotalPages(collection.length, size);

	return {
		content,
		number,
		size,
		numberOfElements: content.length,
		totalElements: collection.length,
		empty: content.length === 0,
		first: number <= 0,
		last: number >= totalPages - 1,
		totalPages,
	};
};

export const buildInfiniteScrollPage = <T>(collection: T[], { number, size }: PageQuery): Page<T> => {
	const totalPages = getTotalPages(collection.length, size);

	return {
		content: collection,
		number,
		size,
		numberOfElements: collection.length,
		totalElements: collection.length,
		empty: collection.length === 0,
		first: number <= 0,
		last: number >= totalPages - 1,
		totalPages,
	};
};

const getTotalPages = (totalElements: number, pageSize: number) => 1 + Math.floor((totalElements - 1) / pageSize);
