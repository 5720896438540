import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Survey } from '@evasys/globals/evainsights/models/survey/survey.entity';
import { UiConfigService } from '../../../index';
import { ParticipationEvent } from '@evasys/globals/evainsights/models/participation/participation-event.model';
import { isNullish } from '@evasys/globals/shared/helper/typeguard';

@Injectable({
	providedIn: 'root',
})
export class FormatterService {
	private readonly uiConfigService = inject(UiConfigService);

	uiConfig = this.uiConfigService.getUiConfig();

	public formatSurvey(survey: Survey) {
		return this.uiConfig.pipe(
			map((config) =>
				config.showParticipationEventCodeInName
					? this.appendCodeIfNotExists(survey.description, survey.participationEventCode)
					: survey.description
			)
		);
	}

	public formatParticipationEvent(participationEvent: ParticipationEvent) {
		return this.uiConfig.pipe(
			map((config) => {
				return config.showParticipationEventCodeInName
					? this.appendCodeIfNotExists(participationEvent.topic, participationEvent.code)
					: participationEvent.topic;
			})
		);
	}

	private appendCodeIfNotExists(description: string, code?: string) {
		if (isNullish(code) || description.includes(`(${code})`)) {
			return description;
		}
		return `${description} (${code})`;
	}
}
