<div class="m-3" *ngIf="report | async; let r" data-cy="reportMetadata">
	<evasys-header [headerLevel]="5" [id]="'report-metadata'" [text]="'report.metadata' | transloco"></evasys-header>
	<span class="h6 span-creator mt-1">
		{{ 'common.status.createdOn' | transloco }}
		{{
			getDateTimeInSystemTimezone(r.createdOn)
				| date : (timeDateFormat | async) : '' : translocoService.getActiveLang()
		}}</span
	>
	<span class="h6 span-creator">
		{{ 'common.status.updatedOn' | transloco }}
		{{
			getDateTimeInSystemTimezone(r.updatedOn)
				| date : (timeDateFormat | async) : '' : translocoService.getActiveLang()
		}}</span
	>
</div>
