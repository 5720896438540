<evainsights-report-item-wrapper
	[reportItemStatus]="reportItemStatus"
	[titleText]="translatedTitle | async"
	[isExportable]="content.data.wordcloudContent.data.length > 0"
>
	<evainsights-info-popover
		*ngLet="reportLanguage$ | async | reportLanguageCode as languageCode"
		[language]="languageCode"
		infoPopover
		[id]="'wordcloudResponse'"
	>
		<div
			[innerHTML]="
				languageCode | inlineTransloco : 'reportItem.visualizationExplanation.wordCloudResponses' | async
			"
		></div>
	</evainsights-info-popover>

	<div class="row c-flex-1 c-min-h-0">
		<div class="col-sm-5 h-100">
			<evainsights-wordcloud-content
				evainsightsClickableWords
				*ngIf="wordcloudContent"
				data-cy="wordcloudContent"
				[content]="wordcloudContent"
				[reportItemStatus]="reportItemStatus"
				[language]="reportLanguage$ | async"
				(searchValueChange)="searchValueChange($event)"
			></evainsights-wordcloud-content>
		</div>
		<div class="col-sm-7 pb-2 h-100">
			<evainsights-topic-responses
				[reportItemStatus]="reportItemStatus"
				[language]="reportLanguage$ | async"
				[tableDataProvider]="tableDataProvider"
				(scrolledToBottom)="loadMoreData()"
			>
			</evainsights-topic-responses>
		</div>
	</div>
</evainsights-report-item-wrapper>
