<form [formGroup]="reportCreatorForm">
	<div class="d-flex gap-3">
		<evasys-anchor
			class="ms-lg-auto"
			[link]="(report | async).id | modify : getReportItemAndReportTemplateItemBatchCreatorPage"
			[design]="buttonDesign.LINK"
			[isDisabled]="reportCreatorForm.invalid || (saving | async) || (report | async).id === 0"
			[id]="'addReportItems'"
			[title]="'report.create.items.hover' | transloco"
		>
			{{ 'report.create.items.label' | transloco }}
		</evasys-anchor>
		<evasys-anchor
			[link]="(report | async).id | modify : getReportItemAndReportTemplateItemCreatorPage"
			[isDisabled]="reportCreatorForm.invalid || (saving | async) || (report | async).id === 0"
			[id]="'addItem'"
			[title]="'report.create.item.hover' | transloco"
		>
			{{ 'report.create.item.label' | transloco }}
		</evasys-anchor>
	</div>
	<hr />
	<div *ngLet="report | async as report" class="d-lg-flex p-0 mt-5 mb-3 gap-3 justify-content-lg-center">
		<evainsights-editable-text
			[id]="'title'"
			[isEditDisabled]="(saving | async) || (report | modify : isReportInvalid)"
			[formValid]="reportCreatorForm.controls['title'].valid"
			[errors]="[
				{
				  id: 'titleRequired',
				  trigger: report.id !== 0 && (reportCreatorForm.controls['title'].errors?.required || reportCreatorForm.controls['title'].errors?.['whitespace']),
				  text: 'report.title.required' | transloco
				},
				{
				  id: 'titleMaxLengthExceeded',
				  trigger: report.id !== 0 && reportCreatorForm.controls['title'].errors?.maxlength,
				  text: 'form.error.maxLengthExceeded' | transloco: { maxChars: maxLengthReportTitle }
				}
		    ]"
			ngDefaultControl
			class="w-100 c-mw-75"
			formControlName="title"
			(editModeChange)="onReportTitleEditModeChange($event)"
			required
			>{{ 'common.form.title.label' | transloco }}
		</evainsights-editable-text>
	</div>
</form>
