<ng-container *ngIf="visible">
	<div [class.open]="!collapsed" [class.overflow-hidden]="collapsed" class="sidebar h-100">
		<button
			class="border-0 toggle-button d-flex w-100 py-2 px-3 c-text-align-left c-nowrap"
			data-cy="button-clickSidebar-toggle"
			[class.h-100]="collapsed"
			(click)="toggleCollapsed()"
			[title]="'common.dashboard.contextMenu.hover' | transloco"
		>
			<img
				evasysImage
				[alt]="'Icon'"
				class="c-w-24px-i c-h-24px-i me-2 c-vertical-align-top"
				id="clickSidebar-toggle"
				[isIcon]="true"
				[src]="collapsed ? 'assets/images/icons/deltaleft2.svg' : 'assets/images/icons/deltaright2.svg'"
			/>
			<ng-container *ngIf="!collapsed">{{ title }}</ng-container>
		</button>

		<ng-content></ng-content>
	</div>
</ng-container>
