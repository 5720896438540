import { ResponseSentiment } from '../models/search/response.model';
import { Sentiment } from '../constants/types';
import { escapeRegExp } from 'lodash';

export function filterResponseSentimentsByText<T extends ResponseSentiment>(
	text: string | undefined,
	responseSentiments: T[]
): T[] {
	if (text === undefined || text.trim() === '') return responseSentiments;

	const escapedText = escapeRegExp(text.trim());
	const regExpQuery = new RegExp(escapedText, 'i');
	return responseSentiments.filter((obj) => regExpQuery.test(obj.response.value));
}

export function filterResponseSentimentsBySentiment<T extends ResponseSentiment>(
	sentiments: Sentiment[],
	responseSentiments: T[]
): T[] {
	if (sentiments.length > 0) {
		return responseSentiments.filter((obj) => sentiments.includes(obj.sentiment));
	}
	return responseSentiments;
}
