import { Injectable } from '@angular/core';
import { assertNotNullish } from '@evasys/globals/shared/helper/typeguard';
import { uniq } from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class SvgRasterizationService {
	/**
	 * Matches an url expression in css property values such as in
	 * ```
	 * src: url("/assets/font.ttf") format('truetype')
	 *      ^^^^^^^^^^^^^^^^^^^^^^^
	 * ```
	 */
	private readonly urlExprRegExp = /url\(['"]?(.*?)['"]?\)/g;
	private standaloneFontCss?: string;

	public readonly rasterize = async (svg: SVGSVGElement, imageMimeType: string, scale = 2) => {
		const image = new Image();
		image.width = Math.round(scale * svg.width.baseVal.value);
		image.height = Math.round(scale * svg.height.baseVal.value);
		image.src = URL.createObjectURL(this.getSvgBlob(await this.withStandaloneFonts(svg)));

		return await new Promise<Blob>(
			(resolve) =>
				(image.onload = () => {
					const canvas = document.createElement('canvas');
					canvas.width = image.width;
					canvas.height = image.height;

					const ctx = canvas.getContext('2d');
					assertNotNullish(ctx);
					ctx.fillStyle = '#fff';
					ctx.fillRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
					URL.revokeObjectURL(image.src);

					canvas.toBlob((blob) => {
						assertNotNullish(blob);
						if (blob.type !== imageMimeType) {
							throw Error(`Export as ${imageMimeType} is not supported by the browser`);
						}
						resolve(blob);
					}, imageMimeType);
				})
		);
	};

	/**
	 * Returns a copy of the `svg` node with an additional style sheet that contains all font families used on the site
	 * as standalone data URLs, not requiring any network requests.
	 */
	protected readonly withStandaloneFonts = async (svg: SVGSVGElement) => {
		const copy = svg.cloneNode(true);
		if (!(copy instanceof SVGSVGElement)) {
			throw Error('Clone of SVG element unexpectedly returned a node with different type');
		}

		const defs = document.createElement('defs');
		copy.appendChild(defs);
		const style = document.createElement('style');
		defs.appendChild(style);
		style.innerHTML = await this.getStandaloneFontStyleCss();

		return copy;
	};

	protected readonly getSvgBlob = (svg: SVGSVGElement) => {
		const svgString = new XMLSerializer().serializeToString(svg);
		return new Blob([svgString], {
			type: 'image/svg+xml;charset=utf-8',
		});
	};

	protected readonly getStandaloneFontStyleCss = async () => {
		if (this.standaloneFontCss === undefined) {
			this.standaloneFontCss = await this.createStandaloneFontCss();
		}
		return this.standaloneFontCss;
	};

	protected readonly createStandaloneFontCss = async () => {
		const fontFaceRules = this.getDocumentFontFaceRules();
		const srcUrls = fontFaceRules.flatMap(this.getSrcUrls);
		const dataUrlByUrl = await this.resolveUrlsToDataUrls(srcUrls);
		const ruleCssTexts = fontFaceRules.map((rule) =>
			rule.cssText.replaceAll(this.urlExprRegExp, (_, url) => `url('${dataUrlByUrl.get(url)}')`)
		);
		return ruleCssTexts.join('\n');
	};

	protected readonly getDocumentFontFaceRules = (): CSSFontFaceRule[] =>
		Array.from(document.styleSheets).flatMap((sheet) => {
			try {
				return Array.from(sheet.cssRules).filter(this.isCssFontFaceRule);
			} catch (error) {
				console.warn("Couldn't access stylesheet rules due to CORS policy:", sheet.href);
				return [];
			}
		});

	protected readonly isCssFontFaceRule = (value: unknown): value is CSSFontFaceRule =>
		value instanceof CSSFontFaceRule;

	protected readonly getSrcUrls = (rule: CSSFontFaceRule): string[] => {
		const matches = Array.from(rule.style.getPropertyValue('src').matchAll(this.urlExprRegExp));
		return matches.map((match) => match[1]);
	};

	protected readonly resolveUrlsToDataUrls = async (urls: string[]) =>
		new Map<string, string>(
			await Promise.all(
				uniq(urls).map(async (url) => {
					const response = await fetch(url);
					const blob = await response.blob();
					const dataUrl = await this.getBlobAsDataUrl(blob);
					return [url, dataUrl] satisfies [string, string];
				})
			)
		);

	protected readonly getBlobAsDataUrl = (blob: Blob) => {
		const fileReader = new FileReader();
		const promise = new Promise<string>((resolve) => {
			fileReader.addEventListener('load', () => {
				resolve(fileReader.result as string);
			});
		});
		fileReader.readAsDataURL(blob);

		return promise;
	};
}
