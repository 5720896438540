<evasys-table
	evasysTableAreaNestedElement
	[id]="'responses'"
	[fixedLayout]="false"
	(sortAction)="tableDataProvider?.onSort($event)"
>
	<thead class="c-z-index-1 rounded bg-white">
		<tr>
			<th
				evasysTableHead
				scope="col"
				data-cy="evasysTableHead-responsesTable-score"
				[position]="'start'"
				class="w-content"
				[title]="'topic.responses.score' | transloco"
			>
				{{ 'topic.responses.score' | transloco }}
			</th>
			<th
				evasysTableHead
				scope="col"
				data-cy="evasysTableHead-responsesTable-title"
				[position]="'start'"
				[title]="'topic.responses.hover' | transloco"
			>
				{{ 'reportItem.response' | transloco }}
			</th>
		</tr>
	</thead>
	<tbody>
		<ng-container *ngIf="tableDataProvider.latestPage | async; let page">
			<ng-container *ngFor="let content of page.content">
				<tr class="border-top">
					<td evasys-table-column [horizontalPosition]="'start'">
						{{ content.overallTopicAssignmentScore | percent : '1.0' }}
					</td>
					<td evasys-table-column [horizontalPosition]="'start'" evasysShowMoreContainer>
						<evasys-show-more [id]="'response-' + content.response.key">
							<evainsights-topic-response-text
								[topicResponse]="content"
							></evainsights-topic-response-text>
						</evasys-show-more>
					</td>
				</tr>
			</ng-container>
		</ng-container>
	</tbody>
</evasys-table>
