<form class="w-100">
	<ng-template #resultTemplate let-result let-term="term" let-text="text">
		<evainsights-typeahead-filter-result
			[id]="result | modify : getResultId"
			[contextInfos]="result | modify : getContextInfos | async"
			[text]="result.text | translateMultiLang | async"
			[term]="term"
			[placeholder]="result | modify : getPlaceholderTranslocoKey | transloco"
		>
		</evainsights-typeahead-filter-result>
	</ng-template>

	<evainsights-filter-typeahead
		[errors]="[
			{
				id: 'noItems',
				trigger: submitted && control?.errors?.required,
				text: 'items.required' | transloco
			}
		]"
		[formatter]="resultFormatter"
		[title]="'item.label' | transloco"
		[id]="'items'"
		[search]="searchItemCollections"
		[showSelectAll]="false"
		[childItemIdentifier]="'items'"
		[filterAreaControls]="filterAreaControls"
		[design]="design"
		[searchFailedText]="'error.text' | transloco"
		[formControl]="control"
		[resultTemplate]="resultTemplate"
		[emptyResultsText]="'items.noneFound' | transloco"
		[isMultiSelect]="isMultiSelect"
	>
		{{ 'items.label' | transloco }}
	</evainsights-filter-typeahead>

	<evasys-error
		[errors]="[
			{
				id: 'sameItemType',
				trigger: control?.errors?.sameItemType,
				text: 'items.error.sameItemType' | transloco
			},
			{
				id: 'sameNonAbstentionItemOptionCount',
				trigger: control?.errors?.sameNonAbstentionItemOptionCount,
				text: 'items.error.sameNonAbstentionItemOptionCount' | transloco
			},
			{
				id: 'sameAbstentionAllowed',
				trigger: control?.errors?.sameAbstentionAllowed,
				text: 'items.error.sameAbstentionAllowed' | transloco
			}
		]"
	></evasys-error>
</form>
