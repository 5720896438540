<div class="row gx-0 align-items-start p-2 m-0 text-nowrap">
	<evasys-accordion [alwaysOpen]="true" [id]="'configurationMenu'">
		<div evasys-accordion-item [id]="'actions'" [show]="true" [title]="'common.actions.label' | transloco">
			<evainsights-report-language-dropdown [report]="report"></evainsights-report-language-dropdown>
			<div class="d-flex flex-row flex-nowrap gap-2">
				<evasys-anchor
					*ngIf="isReportStatusDraft()"
					[link]="report.id | modify : getReportAndReportTemplatePage"
					[id]="'edit-' + id + '-' + report.id"
					class="w-auto d-inline-block"
					[title]="title"
					[design]="buttonDesignLink"
				>
					<img
						evasysImage
						[isIcon]="true"
						[class]="'c-w-20px c-h-20px'"
						[id]="'edit-' + id + '-' + report.id"
						[src]="'./assets/images/icons/edit.svg'"
						[alt]="title"
					/>
					{{ title }}
				</evasys-anchor>

				<evainsights-report-export-menu
					*ngIf="reportType === reportTypeEnum.REPORT"
				></evainsights-report-export-menu>
			</div>
		</div>
	</evasys-accordion>
	<evainsights-report-metadata></evainsights-report-metadata>
	<ng-content></ng-content>
</div>
