import {
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { TopicResponse } from '@evasys/globals/evainsights/models/search/response.model';
import { FilterService, TableDataProvider } from '@evasys/evainsights/shared/core';
import { FilterSelection } from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { TopicResponsesTableFilter } from '@evasys/globals/evainsights/models/filter/topic-responses-table.model';

@Component({
	selector: 'evainsights-topic-responses',
	templateUrl: './topic-responses.component.html',
	styleUrls: ['./topic-responses.scss'],
})
export class TopicResponsesComponent extends WidgetBaseComponent implements OnChanges {
	showSearchBox = false;

	@Input({ required: true })
	tableDataProvider!: TableDataProvider<TopicResponse, TopicResponsesTableFilter>;

	@Input()
	reportItemStatus!: ReportItemContentStatus;

	@Output()
	scrolledToBottom = new EventEmitter<void>();

	@ViewChild('infiniteScrollContainer', { static: false })
	scrollContainer!: ElementRef;

	renderer = inject(Renderer2);

	protected filterService = inject(FilterService);
	filterAreaControls = [this.filterService.sentiments];
	filter: FilterSelection<typeof this.filterAreaControls> = { sentiments: [] };

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['reportItemStatus']) {
			this.showSearchBox = this.reportItemStatus === ReportItemContentStatus.PROCESSED;
		}
	}

	onSelectionChange(filterSelection: FilterSelection<typeof this.filterAreaControls>) {
		this.tableDataProvider.setFilter(filterSelection);
	}

	setScrollbarToTop() {
		if (this.scrollContainer !== undefined) {
			this.renderer.setProperty(this.scrollContainer.nativeElement, 'scrollTop', 0);
		}
	}

	onScrolledToBottom() {
		this.scrolledToBottom.emit();
	}
}
