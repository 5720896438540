import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	map,
	Observable,
	of,
	pipe,
	startWith,
	Subject,
	switchMap,
} from 'rxjs';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { Survey } from '@evasys/globals/evainsights/models/survey/survey.entity';
import {
	FilterService,
	FormatterService,
	ReportService,
	searchRequestFilterParams,
	SurveyService,
} from '@evasys/evainsights/shared/core';
import { FilterSelection } from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { getReportIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { ActivatedRoute } from '@angular/router';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import { PageRequest } from '@evasys/globals/shared/types/page-request.types';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ResultTemplateContext } from '../../filter-typeahead/filter-typeahead.component';
import { tap } from 'rxjs/operators';
import { RespondentFilter } from '@evasys/globals/evainsights/models/report/item.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { exhaustAllWithTrailing } from '@evasys/shared/util';
import { ReportSurveyChanges } from '@evasys/globals/evainsights/models/report/report-survey-changes.model';
import { MAX_REPORT_SURVEYS } from '@evasys/globals/evainsights/constants/report-surveys';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { NotificationService } from '@evasys/shared/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'evainsights-report-survey-list-modal',
	templateUrl: './report-survey-list-modal.component.html',
})
export class ReportSurveyListModalComponent implements OnChanges {
	private readonly formatterService = inject(FormatterService);
	private reportService = inject(ReportService);
	private activatedRoute = inject(ActivatedRoute);
	readonly surveyService = inject(SurveyService);
	private filterService = inject(FilterService);
	notificationService = inject(NotificationService);
	translocoService = inject(TranslocoService);

	@Input()
	resultTemplate!: TemplateRef<ResultTemplateContext<Survey[]>>;
	@Input()
	selectedSurveys!: Survey[];
	@Input()
	reportRespondentFilter!: RespondentFilter;
	@Input()
	isOpen!: boolean;

	@Output()
	public isOpenChange = new EventEmitter<boolean>();
	@Output()
	public closeWithChanges = new EventEmitter<ReportSurveyChanges>();

	filterAreaControls = [
		this.filterService.units,
		this.filterService.forms,
		this.filterService.participationEvents,
		this.filterService.periods,
		this.filterService.programmes,
		this.filterService.leaders,
	];
	hasValidationErrorRequired = false;
	hasValidationErrorLimitExceeded = false;
	pageNumber$ = new BehaviorSubject(1);
	pageSize$ = new BehaviorSubject(10);
	isSurveyUpdated$ = new Subject<void>();
	reportSurveyUpdates = new Subject<Observable<unknown>>();
	reportId!: number;
	isLoading = true;
	surveys$!: Observable<Page<Survey>>;
	reportSurveyChanges = 0;
	maxReportSurveys = MAX_REPORT_SURVEYS;

	protected readonly ChipTypeaheadDesignEnum = ChipTypeaheadDesignEnum;
	protected readonly ButtonDesignEnum = ButtonDesignEnum;

	constructor() {
		this.reportSurveyUpdates
			.pipe(takeUntilDestroyed(), map(pipe(catchError(() => of()))), exhaustAllWithTrailing())
			.subscribe(() => {
				this.isSurveyUpdated$.next();
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['isOpen'] && this.isOpen) {
			this.hasValidationErrorRequired = this.selectedSurveys.length === 0;
			this.hasValidationErrorLimitExceeded = this.selectedSurveys.length > MAX_REPORT_SURVEYS;

			this.reportSurveyChanges = 0;
			this.surveys$ = combineLatest([
				this.pageSize$,
				this.pageNumber$,
				this.activatedRoute.paramMap,
				this.isSurveyUpdated$.pipe(startWith(null)),
			]).pipe(
				switchMap(([size, page, paramMap]) => {
					this.isLoading = true;
					this.reportId = getReportIdFromParamMap(paramMap);
					return this.reportService.getReportSurveysForReportId(this.reportId, {
						size,
						page: page - 1,
					});
				}),
				tap(() => (this.isLoading = false))
			);
		}
	}

	formatterSurvey = (survey: Survey) => survey.description;

	searchSurveys = (
		description: string,
		filter: FilterSelection<typeof this.filterAreaControls>,
		pageRequest: PageRequest
	): Observable<Page<Survey>> => {
		return this.surveyService.getSearchResult({
			description: description,
			...searchRequestFilterParams(
				{ ...this.activatedRoute.snapshot.queryParams, pageSize: pageRequest.size, page: pageRequest.page },
				filter
			),
		});
	};

	onSelectSurvey(surveys: Survey[]) {
		this.updateReportSurveys(surveys);
	}

	onRemoveSurvey(surveyId: number) {
		this.updateReportSurveys(this.selectedSurveys.filter((survey) => survey.id !== surveyId));
	}

	updateReportSurveys(surveys: Survey[]) {
		this.isLoading = true;
		this.hasValidationErrorRequired = surveys.length === 0;
		this.hasValidationErrorLimitExceeded = surveys.length > MAX_REPORT_SURVEYS;
		this.selectedSurveys = surveys;
		this.reportSurveyChanges++;
		if (this.selectedSurveys.length > 0) {
			this.reportSurveyUpdates.next(
				this.reportService
					.patch(this.reportId, {
						surveyIds: this.selectedSurveys.map((survey) => survey.id),
					})
					.pipe(
						catchError(() => {
							this.notificationService.addNotification(
								NotificationEnum.ERROR,
								this.translocoService.translate('survey.error.limitExceeded'),
								'surveyLimitExceeded',
								false
							);
							return of(null);
						})
					)
			);
		}
	}

	openedChangeEmit($event: boolean | undefined) {
		this.isOpenChange.emit($event);
		this.closeWithChanges.emit({
			numberChanges: this.reportSurveyChanges,
			selectedSurveys: this.selectedSurveys,
		});
	}

	public onPageSizeChange(value: number) {
		this.pageSize$.next(value);
		this.pageNumber$.next(0);
	}

	public onPageChange(value: number) {
		this.pageNumber$.next(value);
	}

	protected readonly formatSurveyDescription = (survey: Survey) => this.formatterService.formatSurvey(survey);
}
