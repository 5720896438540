import { map, Observable } from 'rxjs';
import { BreadcrumbItem } from '@evasys/shared/ui';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class BreadcrumbUpdateService {
	constructor(readonly translocoService: TranslocoService) {}

	updateLastBreadItem(title: string, breadItems: Observable<BreadcrumbItem[]>): Observable<BreadcrumbItem[]> {
		return breadItems.pipe(
			map((value) => {
				value[value.length - 1] = {
					id: 'report-creator',
					name: this.translocoService.translate('report.edit.reportCreator', {
						title: title,
					}),
					queryParamsHandling: 'preserve',
				};
				return value;
			})
		);
	}
}
