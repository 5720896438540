import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { TopicResponse } from '@evasys/globals/evainsights/models/search/response.model';
import { TopicAssignment } from '@evasys/globals/evainsights/models/topic-modeling/topic-assignment.model';
import { Span } from '@evasys/globals/evainsights/models/common/span.model';
import { scaleThreshold } from 'd3';

@Component({
	selector: 'evainsights-topic-response-text',
	templateUrl: './topic-response-text.component.html',
})
export class TopicResponseTextComponent implements OnChanges {
	@Input()
	topicResponse!: TopicResponse;

	@HostBinding('attr.data-cy')
	dataCy?: string;

	segments: TextSegment[] = [];

	ngOnChanges(): void {
		this.dataCy = `topicResponseText-${this.topicResponse.response.key}`;

		this.segments = [];
		for (const assignment of this.getContinuousAssignments()) {
			this.segments.push({
				content: this.getTextSpan(assignment.span),
				style: {
					// color is the design guide "Mint Green"
					backgroundColor: `rgba(198, 234, 209, ${highlightAlphaScale(assignment.score)})`,
					borderRadius: '0.1rem',
				},
			});
		}
	}

	// Adds zero-score assignments for the text parts that otherwise do not have an assignment.
	getContinuousAssignments = (): TopicAssignment[] => {
		const sortedAssignments = [...this.topicResponse.topicAssignments].sort((a, b) => a.span.start - b.span.start);

		const continuousAssignments: TopicAssignment[] = [];
		let position = 0;
		for (const assignment of sortedAssignments) {
			if (assignment.span.start > position) {
				continuousAssignments.push({
					span: { start: position, end: assignment.span.start },
					score: 0.0,
				});
			}

			continuousAssignments.push(assignment);
			position = assignment.span.end;
		}

		const responseLength = this.topicResponse.response.value.length;
		if (position < responseLength) {
			continuousAssignments.push({
				span: { start: position, end: responseLength },
				score: 0.0,
			});
		}

		return continuousAssignments;
	};

	getTextSpan = (span: Span) => this.topicResponse.response.value.slice(span.start, span.end);
}

const highlightAlphaScale = scaleThreshold().domain([0.2, 0.4, 0.6]).range([0, 0.3, 0.45, 0.6]);

type Style = { [klass: string]: any };

interface TextSegment {
	content: string;
	style: Style;
}
