import { Component, computed, ElementRef, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { ReportItem } from '@evasys/globals/evainsights/models/report-item';
import { ExportMenuComponent } from '../../export-menu/export-menu.component';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { ExportFormat } from '@evasys/globals/evainsights/constants/data-types';

@Component({
	selector: 'evainsights-report-item',
	templateUrl: './report-item.component.html',
	styleUrls: ['./report-item.component.scss'],
})
export class ReportItemComponent {
	@Input({ required: true })
	set reportItem(value: ReportItem) {
		this._reportItem.set(value);
	}

	@Input()
	editable = false;

	@Input()
	language?: ReportLanguage;

	@Input({ required: true })
	set exportFormats(value: ExportFormat[]) {
		this._exportFormats.set(value);
	}

	@Output()
	selectExportFormat = new EventEmitter<ExportFormat>();

	@Output()
	configure = new EventEmitter<void>();

	@Output()
	delete = new EventEmitter<void>();

	@ViewChild('exportMenu')
	exportMenu?: ExportMenuComponent;

	_reportItem = signal(null as unknown as ReportItem);
	isCard = computed(() => this._reportItem().content.config.visualizationType !== VisualizationType.DIVIDER);
	bgClass = computed(() => (this.isCard() ? 'c-bg-white' : 'c-bg'));
	isConfigurable = computed(() => this._reportItem().content.config.visualizationType !== VisualizationType.DIVIDER);
	_exportFormats = signal(null as unknown as ExportFormat[]);
	isDownloadable = computed(() => this._exportFormats().length > 0);

	buttonLinkDesign = ButtonDesignEnum.LINK;

	protected readonly visualizationType = VisualizationType;

	constructor(public elementRef: ElementRef) {
		/* this is intentional */
	}
}
