<div class="reportItem-container shadow-sm pt-1 h-100" [ngClass]="bgClass()">
	<div
		class="reportItem-hoverMenu c-z-index-1 d-none position-absolute top-0 end-0 c-h-2rem"
		[ngClass]="bgClass()"
		[class.show]="exportMenu?.isOpen"
		*ngIf="editable || isDownloadable()"
	>
		<evainsights-export-menu
			#exportMenu
			*ngIf="isDownloadable()"
			[id]="'reportItem-' + _reportItem().id"
			[exportFormats]="_exportFormats()"
			(selectFormat)="selectExportFormat.emit($event)"
			optionTranslocoKey="reportItem.export.one"
			class="d-inline-block"
		>
			<evasys-button
				[id]="'exportChart-' + _reportItem().id"
				[design]="buttonLinkDesign"
				*ngIf="_reportItem().content.config.visualizationType !== visualizationType.RESPONSES"
			>
				<img
					evasysImage
					[id]="'downloadImage-' + _reportItem().id"
					[alt]="'reportItem.export.hover' | transloco"
					[class]="'c-w-18px c-h-18px'"
					[altTextAsLabel]="true"
					src="./assets/images/fontawesome/download.svg"
					[title]="'reportItem.export.hover' | transloco"
				/>
			</evasys-button>
		</evainsights-export-menu>
		<ng-container *ngIf="editable">
			<evasys-button [id]="'MoveItem-' + _reportItem().id" [design]="buttonLinkDesign">
				<img
					evasysImage
					[alt]="'common.actions.move' | transloco"
					[class]="'c-w-18px c-h-18px reportGrid-reportItem-dragHandle'"
					[id]="'moveImage-' + _reportItem().id"
					[src]="'./assets/images/icons/updown.svg'"
					[altTextAsLabel]="true"
					[title]="'common.actions.move' | transloco"
				/>
			</evasys-button>
			<evasys-button
				*ngIf="isConfigurable()"
				(click)="configure.emit()"
				[id]="'configureItem-' + _reportItem().id"
				[design]="buttonLinkDesign"
			>
				<img
					evasysImage
					[alt]="'common.actions.edit' | transloco"
					[class]="'c-w-18px c-h-18px'"
					[id]="'configureImage-' + _reportItem().id"
					[src]="'./assets/images/icons/edit.svg'"
					[altTextAsLabel]="true"
					[title]="'common.actions.edit' | transloco"
				/>
			</evasys-button>
			<evasys-button
				(click)="delete.emit()"
				[id]="'deleteReportItem-' + _reportItem().id"
				[design]="buttonLinkDesign"
			>
				<img
					evasysImage
					[alt]="'common.actions.delete' | transloco"
					[class]="'c-w-18px c-h-18px'"
					[id]="'deleteImage-' + _reportItem().id"
					[src]="'./assets/images/icons/delete2.svg'"
					[altTextAsLabel]="true"
					[title]="'common.actions.delete' | transloco"
				/>
			</evasys-button>
		</ng-container>
	</div>

	<evainsights-bar-chart-widget
		*ngIf="_reportItem().content.config.visualizationType === visualizationType.BAR_CHART"
		[id]="_reportItem().id | toString"
		[content]="$any(_reportItem().content)"
		[reportItemStatus]="_reportItem().status"
		[language]="language"
		class="w-100 h-100"
	></evainsights-bar-chart-widget>
	<evainsights-wordcloud-widget
		*ngIf="_reportItem().content.config.visualizationType === visualizationType.WORDCLOUD"
		[content]="$any(_reportItem().content)"
		[reportItemStatus]="_reportItem().status"
		[language]="language"
		class="w-100 h-100"
	></evainsights-wordcloud-widget>
	<evainsights-wordcloud-responses-widget
		*ngIf="_reportItem().content.config.visualizationType === visualizationType.WORDCLOUD_RESPONSES"
		[content]="$any(_reportItem().content)"
		[reportItemStatus]="_reportItem().status"
		[language]="language"
		class="w-100 h-100"
	></evainsights-wordcloud-responses-widget>
	<evainsights-rich-text
		*ngIf="_reportItem().content.config.visualizationType === visualizationType.RICH_TEXT"
		[id]="_reportItem().id | toString"
		[content]="$any(_reportItem().content)"
		[language]="language"
	></evainsights-rich-text>
	<evainsights-divider-widget *ngIf="_reportItem().content.config.visualizationType === visualizationType.DIVIDER">
	</evainsights-divider-widget>
	<evainsights-line-chart-widget
		*ngIf="_reportItem().content.config.visualizationType === visualizationType.LINE_CHART"
		[id]="_reportItem().id | toString"
		[content]="$any(_reportItem().content)"
		[reportItemStatus]="_reportItem().status"
		[language]="language"
		class="w-100 h-100"
	></evainsights-line-chart-widget>
	<evainsights-responses-widget
		*ngIf="_reportItem().content.config.visualizationType === visualizationType.RESPONSES"
		[id]="_reportItem().id | toString"
		[content]="$any(_reportItem().content)"
		[reportItemStatus]="_reportItem().status"
		[language]="language"
		class="w-100 h-100"
	></evainsights-responses-widget>
</div>
