<evasys-chip-typeahead
	#chipTypeahead
	[id]="id"
	[formatter]="formatter"
	[chipContentTemplate]="chipContentTemplate"
	[maxTotalSelectedElements]="maxTotalSelectedElements"
	[items]="searchFiltered"
	[disabled]="disabled"
	[errors]="errors"
	[itemIdentifier]="itemIdentifier"
	[searchFailedText]="searchFailedText"
	[emptyResultsText]="emptyResultsText"
	[autoFocus]="autoFocus"
	[ngModel]="selected"
	(ngModelChange)="select($event)"
	[resultTemplate]="resultTemplate"
	[header]="typeaheadPopoverHeader"
	[popoverClass]="popoverClass"
	[popoverContainer]="popoverContainer"
	[design]="design"
	[placement]="placement"
	[childItemIdentifier]="childItemIdentifier"
	[showSelectAll]="showSelectAll"
	[chipVisibleSize]="chipVisibleSize"
	*ngIf="isMultiSelect"
>
	{{ title }}
</evasys-chip-typeahead>
<evasys-typeahead
	#typeahead
	[id]="id"
	[formatter]="formatter"
	[items]="searchFiltered"
	[errors]="errors"
	[disabled]="disabled"
	[itemIdentifier]="itemIdentifier"
	[searchFailedText]="searchFailedText"
	[emptyResultsText]="emptyResultsText"
	[autoFocus]="autoFocus"
	[ngModel]="selected"
	(ngModelChange)="select($event)"
	[resultTemplate]="resultTemplate"
	[popoverClass]="popoverClass"
	[header]="typeaheadPopoverHeader"
	[autoClosePopoverOnSelect]="true"
	[childItemIdentifier]="childItemIdentifier"
	[disableParentItems]="!!childItemIdentifier"
	placement="bottom top"
	*ngIf="!isMultiSelect"
>
	{{ title }}
</evasys-typeahead>

<ng-template #typeaheadPopoverHeader>
	<div class="d-flex flex-row-reverse">
		<evasys-button
			[id]="'filterTypeahead-' + id + '-close'"
			[design]="buttonStyle.LINK"
			(click)="isMultiSelect ? chipTypeahead?.typeahead.close() : typeahead?.close()"
		>
			<img
				evasysImage
				[id]="'close'"
				[alt]="'close-icon'"
				[class]="'c-w-20px c-h-20px'"
				[src]="'./assets/images/icons/navigate_cross.svg'"
			/>
		</evasys-button>
	</div>
	<div class="px-4">
		<evainsights-typeahead-filter-area
			[id]="id"
			[filterAreaControls]="filterAreaControls"
			[filterSelections]="filter"
			(filterSelectionsChange)="onFilterChange($event)"
		></evainsights-typeahead-filter-area>
	</div>
	<hr class="text-primary" />
	<div class="h4 text-center mt-3">
		{{ 'common.form.filter.results' | transloco }}
	</div>
</ng-template>
