import { computed, inject, Injectable, Signal } from '@angular/core';
import { UiConfigService } from '@evasys/evainsights/shared/core';
import { ReportItemContent, TopicWordcloudData } from '@evasys/globals/evainsights/models/report-item';
import { ExportFormat } from '@evasys/globals/evainsights/constants/data-types';
import {
	isBarChartContent,
	isLineChartContent,
	isProfileLineChartContent,
	isTopicWordcloudResponsesContent,
	isWordcloudContent,
} from '@evasys/globals/evainsights/typeguards/report-item-content';
import { WordFrequencyWordcloudData } from '@evasys/globals/evainsights/models/report-item/content/word-frequency-wordcloud-report-item-content.model';

@Injectable({
	providedIn: 'root',
})
export class ReportItemExportService {
	private readonly uiConfigService = inject(UiConfigService);

	private readonly isOpenQuestionExportPermitted = computed(
		() => this.uiConfigService.uiConfig().permitOpenQuestionCsvExport
	);

	public readonly getSupportedExportFormats = (content: ReportItemContent): Signal<ExportFormat[]> =>
		computed(() => {
			if (isTopicWordcloudResponsesContent(content)) {
				const wordcloudExportFormats = this.getWordcloudDataExportFormats(content.data.wordcloudContent);
				const responsesExportFormats =
					this.isOpenQuestionExportPermitted() && content.data.responses.length > 0
						? [ExportFormat.XLSX, ExportFormat.CSV]
						: [];
				return [...wordcloudExportFormats, ...responsesExportFormats];
			} else if (isWordcloudContent(content)) {
				return this.getWordcloudDataExportFormats(content.data);
			} else if (
				isBarChartContent(content) ||
				isLineChartContent(content) ||
				isProfileLineChartContent(content)
			) {
				return [ExportFormat.PNG];
			} else {
				return [];
			}
		});

	private readonly getWordcloudDataExportFormats = (
		data: TopicWordcloudData | WordFrequencyWordcloudData
	): ExportFormat[] => (data.data.length > 0 ? [ExportFormat.PNG] : []);
}
