<div class="d-flex flex-row gap-2 align-items-center" data-cy="colorPicker-label">
	<input
		id="colorPickerInput"
		class="c-w-24px c-h-24px"
		type="button"
		[style.background]="color"
		[style.border-style]="'none'"
		[style.border-radius]="'6px'"
		[cpCancelButton]="true"
		[cpCancelButtonClass]="'btn btn-outline-primary'"
		[cpOutputFormat]="'hex'"
		[cpPresetColors]="presets"
		[cpAddColorButton]="true"
		[(colorPicker)]="color"
		(colorPickerChange)="onColorChanged()"
		[cpAddColorButtonText]="'reportItem.configuration.colorPicker.add' | transloco"
		[cpPresetEmptyMessage]="'reportItem.configuration.colorPicker.nothingAdded' | transloco"
		[cpPresetLabel]="'reportItem.configuration.colorPicker.preset' | transloco"
		[cpCancelButtonText]="'common.actions.cancel' | transloco"
		data-cy="colorPicker-input"
	/>
	<label [for]="'colorPickerInput'">
		<ng-content></ng-content>
	</label>
</div>
