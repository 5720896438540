<div class="container">
	<form [formGroup]="loginform">
		<evasys-textbox
			[errors]="[
				{
					id: 'username',
					trigger: submitted && loginform.controls['username'].errors?.required,
					text: 'login.username.error' | transloco
				}
			]"
			[id]="'username'"
			class="mt-3 mb-3"
			formControlName="username"
			>{{ 'login.username.label' | transloco }}
		</evasys-textbox>
		<evasys-textbox
			[errors]="[
				{
					id: 'password',
					trigger: submitted && loginform.controls['password'].errors?.required,
					text: 'login.password.error' | transloco
				}
			]"
			[id]="'password'"
			[type]="'password'"
			class="mt-3 mb-3"
			formControlName="password"
			>{{ 'login.password.label' | transloco }}
		</evasys-textbox>
		<evasys-button (click)="onSubmit()" [type]="'submit'" class="mt-1" [id]="'submitLogin'"
			>{{ 'login.submit' | transloco }}
		</evasys-button>
	</form>
</div>
