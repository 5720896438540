import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { BehaviorSubject, filter, map, of } from 'rxjs';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import { TopicWordcloudContent, TopicWordcloudResponsesContent } from '@evasys/globals/evainsights/models/report-item';
import { Sentiment, VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';
import { TopicResponse } from '@evasys/globals/evainsights/models/search/response.model';
import { buildInfiniteScrollPage } from '@evasys/globals/evainsights/helper/pagination';
import { TableDataProvider, TableDataProviderWithoutUrl } from '@evasys/evainsights/shared/core';
import { TopicResponsesTableFilter } from '@evasys/globals/evainsights/models/filter/topic-responses-table.model';
import {
	filterResponseSentimentsBySentiment,
	filterResponseSentimentsByText,
} from '@evasys/globals/evainsights/helper/response-sentiment-filter';
import { TopicResponsesComponent } from '../topic-responses/topic-responses.component';

@Component({
	selector: 'evainsights-wordcloud-responses-widget',
	templateUrl: './wordcloud-responses-widget.component.html',
})
export class WordcloudResponsesWidgetComponent extends WidgetBaseComponent implements OnChanges {
	@Input()
	@Required()
	content!: TopicWordcloudResponsesContent;

	@Input()
	reportItemStatus: ReportItemContentStatus = ReportItemContentStatus.PROCESSED;

	@ViewChild(TopicResponsesComponent, { static: true })
	readonly topicResponsesComponent!: TopicResponsesComponent;

	wordcloudContent!: TopicWordcloudContent;

	searchValue = '';

	tableDataProvider: TableDataProvider<TopicResponse, TopicResponsesTableFilter>;

	localParams$ = new BehaviorSubject<TopicResponsesTableFilter>({
		page: 0,
		size: 10,
		sentiments: [],
	});

	readonly loadMoreSize = 10;

	constructor() {
		super();
		let topicResponsesTableData: TopicResponse[] = [];
		this.tableDataProvider = new TableDataProviderWithoutUrl((searchRequest) => {
			const { text, sentiments, page } = searchRequest;
			const moreTopicResponses = this.getTopicResponses(page, this.loadMoreSize, text, sentiments);

			if (page === 0) {
				topicResponsesTableData = moreTopicResponses;
			} else {
				topicResponsesTableData = topicResponsesTableData.concat(moreTopicResponses);
			}

			return of(
				buildInfiniteScrollPage(topicResponsesTableData, {
					number: page,
					size: (page + 1) * this.loadMoreSize,
				})
			);
		}, this.localParams$);
	}

	getTopicResponses(page: number, itemsPerPage: number, text: string | undefined, sentiments: Sentiment[]) {
		const startIndex = page * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		const results: TopicResponse[] = [];

		let topicResponses: TopicResponse[] = this.content.data.responses;
		topicResponses = filterResponseSentimentsByText(text, topicResponses);
		topicResponses = filterResponseSentimentsBySentiment(sentiments, topicResponses);

		for (let i = startIndex; i < endIndex; i++) {
			if (topicResponses[i] !== undefined) {
				results.push(topicResponses[i]);
			}
		}
		return results;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['content']) {
			this.titleObservables.next(
				this.reportLanguage$.pipe(
					filter(isNotNullish),
					map((reportLanguage) => this.getTitleText(reportLanguage))
				)
			);

			this.wordcloudContent = {
				config: {
					...this.content.config,
					visualizationType: VisualizationType.WORDCLOUD,
				},
				data: this.content.data.wordcloudContent,
			};
		}
	}

	getTitleText(reportLanguage: ReportLanguage): string {
		return translateReportMultiLangString(
			this.content.config.title,
			reportLanguage.id,
			this.content.data.wordcloudContent.topic.name
		);
	}

	searchValueChange(event: string) {
		this.tableDataProvider.setSearchText(event);
		this.topicResponsesComponent.setScrollbarToTop();
	}

	loadMoreData() {
		const nextPageNumber = this.localParams$.getValue().page + 1;
		this.tableDataProvider.nextPage(nextPageNumber + 1);
	}
}
