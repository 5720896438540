import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { ParticipationEventSearchRequest } from '@evasys/globals/evainsights/models/search/ParticipationEventSearchRequest';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { ParticipationEvent } from '@evasys/globals/evainsights/models/participation/participation-event.model';

@Injectable({
	providedIn: 'root',
})
export class ParticipationEventService {
	private readonly http = inject(HttpClient);

	getSearchResult(filter: ParticipationEventSearchRequest = {}): Observable<Page<ParticipationEvent>> {
		return this.http.get<Page<ParticipationEvent>>(endpoints.getParticipationEvents.url(), {
			params: { ...filter },
		});
	}
}
