import { Component, Input } from '@angular/core';
import { TableDataProvider } from '@evasys/evainsights/shared/core';
import { TopicResponse } from '@evasys/globals/evainsights/models/search/response.model';
import { TopicResponsesTableFilter } from '@evasys/globals/evainsights/models/filter/topic-responses-table.model';

@Component({
	selector: 'evainsights-response-table',
	templateUrl: './response-table.component.html',
})
export class ResponseTableComponent {
	@Input()
	tableDataProvider!: TableDataProvider<TopicResponse, TopicResponsesTableFilter>;
}
