<div
	class="h-100 d-flex p-0 justify-content-center mx-auto"
	data-cy="reportGrid-wrapper"
	[evainsightsReportLanguageBoundary]="true"
>
	<gridster #gridster [options]="gridsterConfig" class="overflow-x-auto c-bg" data-cy="gridster-report">
		<gridster-item
			*ngFor="let reportItem of report.reportItems; trackBy: trackBy"
			[item]="reportItem | modify : getGridsterItem"
			[attr.data-cy]="'reportGrid-item-' + reportItem.id"
			class="p-0"
		>
			<evainsights-report-item
				#reportItemElement
				[reportItem]="reportItem"
				[language]="this.reportLanguageService.activeReportLanguage()"
				[editable]="editable"
				[exportFormats]="
					(this.reportItemExportService.getSupportedExportFormats | invoke : reportItem.content)()
				"
				(configure)="configureItem(reportItem.id)"
				(delete)="deleteReportItem(reportItem)"
				(selectExportFormat)="
					exportReportItemNode(reportItem, $event, reportItemElement.elementRef.nativeElement)
				"
				class="h-100"
			></evainsights-report-item>
		</gridster-item>
	</gridster>
</div>
