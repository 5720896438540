<div class="card position-relative m-auto shadow-none w-100 pt-1 pb-1 pe-1 h-100" [class.low-opacity]="!showSearchBox">
	<ng-container *ngLet="reportLanguage$ | async | reportLanguageCode as languageCode">
		<div
			class="table-container m-1"
			infiniteScroll
			#infiniteScrollContainer
			[infiniteScrollDistance]="0.2"
			[infiniteScrollThrottle]="150"
			(scrolled)="onScrolledToBottom()"
			[infiniteScrollContainer]="infiniteScrollContainer"
			[attr.data-cy]="'topicResponses-infiniteScrollContainer'"
		>
			<div class="d-flex justify-content-between m-2">
				<evainsights-typeahead-filter-area
					[id]="'sentiments'"
					[filterAreaControls]="filterAreaControls"
					[filterSelections]="filter"
					(filterSelectionsChange)="onSelectionChange($event)"
					[showTitle]="false"
				>
				</evainsights-typeahead-filter-area>
				<evasys-searchbox
					*ngIf="showSearchBox"
					[id]="'responses'"
					[ngModel]="tableDataProvider.searchText"
					[language]="languageCode"
					(ngModelChange)="tableDataProvider.setSearchText($event)"
				></evasys-searchbox>
			</div>
			<evainsights-response-table [tableDataProvider]="tableDataProvider"></evainsights-response-table>
			<evainsights-empty-content
				*ngIf="(tableDataProvider.latestPage | async)?.empty"
				[attr.data-cy]="'emptyContent-response'"
				[message]="languageCode | inlineTransloco : 'responses.noneFound' | async"
			>
			</evainsights-empty-content>
		</div>
	</ng-container>
</div>
