import { ReportItemContent } from '../models/report-item';
import { VisualizationType } from '../constants/types';

const createVisualizationTypeTypeGuard =
	<VT extends VisualizationType>(visualizationType: VT) =>
	(content: ReportItemContent): content is Extract<ReportItemContent, { config: { visualizationType: VT } }> =>
		content.config.visualizationType === visualizationType;

export const isBarChartContent = createVisualizationTypeTypeGuard(VisualizationType.BAR_CHART);
export const isLineChartContent = createVisualizationTypeTypeGuard(VisualizationType.LINE_CHART);
export const isProfileLineChartContent = createVisualizationTypeTypeGuard(VisualizationType.PROFILE_LINE_CHART);
export const isRichTextContent = createVisualizationTypeTypeGuard(VisualizationType.RICH_TEXT);
export const isDividerContent = createVisualizationTypeTypeGuard(VisualizationType.DIVIDER);
export const isWordcloudContent = createVisualizationTypeTypeGuard(VisualizationType.WORDCLOUD);
export const isTopicWordcloudResponsesContent = createVisualizationTypeTypeGuard(VisualizationType.WORDCLOUD_RESPONSES);
export const isResponsesContent = createVisualizationTypeTypeGuard(VisualizationType.RESPONSES);
