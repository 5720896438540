import { ActivatedRoute } from '@angular/router';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	map,
	Observable,
	share,
	startWith,
	Subject,
} from 'rxjs';
import { latestData, LoadingState, trackLoadingStates } from '@evasys/shared/util';
import { SearchPostRequest } from '@evasys/globals/evainsights/models/search/SearchRequest';
import { EvasysSortingModel } from '@evasys/globals/shared/models/general/evasys-sorting.model';
import { SearchSelection } from '@evasys/globals/evainsights/models/search/search-selection.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { SearchRequestParamService } from './search-request-param.service';
import { asArray } from '../../../../../util/src/lib/general/as-array';
import { isEqual } from '@evasys/globals/shared/helper/object';
import { Sentiment } from '@evasys/globals/evainsights/constants/types';
import { getEnumValue } from '@evasys/globals/shared/helper/enum';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';
import {
	Direction,
	PaginatedPostSort,
	PaginatedSearchPostRequest,
} from '@evasys/globals/evainsights/models/search/PaginatedSearchPostRequest';

export abstract class TableDataProvider<T, P extends FilterParams> {
	public reload$ = new Subject<void>();
	public search$ = new Subject<string>();

	public sortOrder: Direction | undefined;
	public sortField: string | undefined;
	public searchText: string | undefined;

	public requests: Observable<LoadingState<Page<T>>>;
	public loading: Observable<boolean>;
	public latestPage: Observable<Page<T>>;

	constructor(readonly search: (filter: P) => Observable<Page<T>>, public readonly filterParams: Observable<P>) {
		this.requests = combineLatest([filterParams, this.reload$.pipe(startWith(null))]).pipe(
			map(([filterP]) => this.search(filterP)),
			trackLoadingStates(),
			share()
		);

		this.loading = this.requests.pipe(
			startWith({ isLoading: true }),
			map((state) => state.isLoading)
		);

		this.latestPage = this.requests.pipe(latestData());
	}

	abstract nextPage(page: number): void;
	abstract setPageSize(size: number): void;
	abstract onSort(sortingModel: EvasysSortingModel): void;
	abstract setSearchText(value: string): void;
	abstract setFilter(param: Partial<P>): void;

	reload() {
		this.reload$.next();
	}
}

export class TableDataProviderWithoutUrl<T, P extends FilterParams> extends TableDataProvider<T, P> {
	constructor(search: (filter: P) => Observable<Page<T>>, readonly localParams$: BehaviorSubject<P>) {
		super(
			search,
			localParams$.pipe(
				map((params) => {
					const textRequest: { text?: string } = {};

					if (params.text !== undefined) textRequest.text = params.text;
					return { ...params, ...textRequest };
				}),
				distinctUntilChanged(isEqual)
			)
		);
	}

	nextPage(page: number) {
		this.setFilterProperty('page', page - 1);
	}

	setPageSize(size: number) {
		this.setFilterProperty('size', size);
	}

	setSearchText(text: string) {
		this.setFilterProperty('text', text);
		this.searchText = text;
	}

	onSort(sortingModel: EvasysSortingModel): void {
		this.sortOrder = sortingModel.sortOrderAscending ? 'asc' : 'desc';
		this.sortField = sortingModel.columnField;

		const postSort: PaginatedPostSort = {
			property: this.sortField,
			direction: this.sortOrder,
		};

		this.setFilterProperty('sort', postSort);
	}

	setFilter(param: Partial<P>) {
		this.localParams$.next({
			...this.localParams$.value,
			page: 0,
			...param,
		});
	}

	private setFilterProperty<K extends keyof P>(key: K, value: P[K]) {
		const update: Partial<P> = {};
		update[key] = value;
		this.setFilter(update);
	}
}

export class DashboardTableDataProvider<T> extends TableDataProvider<T, FilterParams & SearchPostRequest> {
	constructor(
		search: (filter: FilterParams & SearchPostRequest) => Observable<Page<T>>,
		readonly paramService: SearchRequestParamService,
		readonly activatedRoute: ActivatedRoute
	) {
		super(
			search,
			activatedRoute.queryParams.pipe(
				map((params) => {
					const searchRequest = defaultSearchRequestParams(params);

					const textRequest: { text?: string } = {};
					if (params['searchText'] !== undefined) textRequest.text = params['searchText'];

					return { ...searchRequest, sort: toPostSort(params['sort']), ...textRequest };
				}),
				distinctUntilChanged(isEqual)
			)
		);

		const urlSortParam = this.activatedRoute.snapshot.queryParams['sort'];

		if (urlSortParam && urlSortParam.length > 1) {
			const urlSortParts = urlSortParam.split(',');
			this.sortField = urlSortParts[0];
			this.sortOrder = urlSortParts[1];
		}
		const urlSearchTextParam = this.activatedRoute.snapshot.queryParams['searchText'];
		if (urlSearchTextParam?.length) {
			this.searchText = urlSearchTextParam;
		}

		this.listenToSearchTextChanges();
	}

	nextPage(page: number) {
		this.setFilter({ page });
	}

	setPageSize(size: number) {
		this.setFilter({ size });
	}

	setSearchText(value: string) {
		this.search$.next(value);
		this.searchText = value;
	}

	onSort({ columnField, sortOrderAscending }: EvasysSortingModel) {
		this.sortOrder = sortOrderAscending ? 'asc' : 'desc';
		this.sortField = columnField;

		const postSort: PaginatedPostSort = {
			property: this.sortField,
			direction: this.sortOrder,
		};

		this.setFilter({ sort: postSort });
	}

	isSortAscending(sortOrder: string) {
		return sortOrder === 'asc';
	}

	private listenToSearchTextChanges() {
		this.search$.pipe(debounceTime(200)).subscribe((searchText) => {
			this.paramService.updateParams({
				searchText: searchText.length ? searchText : undefined,
			});
		});
	}

	setFilter(param: Partial<FilterParams & SearchPostRequest>): void {
		const { sort, ...rest } = param;
		const sortQuery = sort === undefined ? undefined : `${sort.property},${sort.direction}`;
		this.paramService.updateParams({ ...rest, sort: sortQuery });
	}
}

declare type Params = {
	[key: string]: any;
};

interface FilterParams extends PaginatedSearchPostRequest {
	text?: string;
}

const toPostSort = (sortParam: unknown): PaginatedPostSort | undefined => {
	if (typeof sortParam !== 'string') {
		return undefined;
	}
	const strings = sortParam.split(',');
	if (strings.length !== 2) {
		throw new Error('Unexpected sort: ' + sortParam);
	}
	const [property, direction] = strings;
	if (direction !== 'asc' && direction !== 'desc') {
		throw new Error('Invalid direction argument: ' + direction);
	}
	return { property, direction };
};

export const defaultSearchRequestParams = (params: Params): SearchPostRequest => ({
	page: Number(params['page']) - 1 || 0,
	size: Number(params['pageSize']) || 10,
	units: getParamAsStringArray(params['unitId']).map(Number),
	programmes: getParamAsStringArray(params['programmeId']).map(Number),
	periods: getParamAsStringArray(params['periodId']).map(Number),
	forms: getParamAsStringArray(params['formId']).map(Number),
	participationEvents: getParamAsStringArray(params['participationEventId']).map(Number),
	leaders: getParamAsStringArray(params['leaderId']).map(Number),
	sentiments: getParamAsStringArray(params['sentimentId'])
		.map((str) => getEnumValue(Sentiment, str))
		.filter(isNotNullish),
});

const getParamAsStringArray = (param: string | string[] | undefined) => (param === undefined ? [] : asArray(param));

export const searchRequestFilterParams = (params: Params, searchSelection: SearchSelection): SearchPostRequest => ({
	page: Number(params['page']) || 0,
	size: Number(params['pageSize']),
	units: searchSelection.units.map((unit) => unit.key),
	programmes: searchSelection.programmes.map((programm) => programm.key),
	periods: searchSelection.periods.map((period) => period.key),
	forms: searchSelection.forms.map((form) => form.id),
	participationEvents: searchSelection.participationEvents.map((participationEvent) => participationEvent.id),
	leaders: searchSelection.leaders.map((leader) => leader.key),
});
